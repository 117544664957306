import { render, staticRenderFns } from "./orbis-sidepanel.html?vue&type=template&id=492ed0f3&scoped=true&external"
import script from "./orbis-sidepanel.js?vue&type=script&lang=js&external"
export * from "./orbis-sidepanel.js?vue&type=script&lang=js&external"
import style0 from "./orbis-sidepanel.scss?vue&type=style&index=0&id=492ed0f3&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "492ed0f3",
  null
  
)

export default component.exports